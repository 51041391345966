import { ChangeDetectionStrategy, Component, HostBinding, Inject, OnInit } from '@angular/core';
import { CdkDrag, CdkDragDrop, CdkDropList, copyArrayItem, moveItemInArray } from '@angular/cdk/drag-drop';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { NgClass, NgFor } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { generateRow } from 'src/app/utils/profile.utils';
import { IPosition } from 'src/app/shared/model/position.interface';

@Component({
  selector: 'hb-position-modal',
  templateUrl: './position-modal.component.html',
  styleUrls: ['./position-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CdkDropList, NgFor, CdkDrag, NgClass, MatButtonModule, MatIconModule]
})
export class PositionModalComponent implements OnInit {
  @HostBinding('class.background-host-page') public backgroundHostPageClass = true;
  public selectedPositions: IPosition[] = [];

  public rowArrays: Record<string, IPosition[]> = {
    firstRow: generateRow('ST', 'red', 'firstRow'),
    secondRowOne: generateRow('AL', 'red', 'secondRowOne'),
    secondRowTwo: generateRow('AM', 'red', 'secondRowTwo'),
    secondRowThree: generateRow('AR', 'red', 'secondRowThree'),
    thirdRowOne: generateRow('ML', 'blue', 'thirdRowOne'),
    thirdRowTwo: generateRow('MC', 'blue', 'thirdRowTwo'),
    thirdRowThree: generateRow('MR', 'blue', 'thirdRowThree'),
    forthRow: generateRow('DM', 'blue', 'forthRow'),
    fifthRowOne: generateRow('DL', 'green', 'fifthRowOne'),
    fifthRowTwo: generateRow('DC', 'green', 'fifthRowTwo'),
    fifthRowThree: generateRow('DR', 'green', 'fifthRowThree'),
    sixthRow: generateRow('GK', 'yellow', 'sixthRow')
  };
  constructor(
    private dialogRef: MatDialogRef<PositionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IPosition[]
  ) {}

  public ngOnInit(): void {
    if (this.data?.length) {
      this.selectedPositions = this.data;
      for (const position of this.data) {
        this.checkIfAdded(true, position);
      }
    }
  }

  public navigateToEventsPage(): void {
    this.dialogRef.close();
  }

  public resetForm(): void {
    for (const position of this.selectedPositions) {
      this.checkIfAdded(false, position);
    }
    this.selectedPositions = [];
  }

  public submitForm(): void {
    this.dialogRef.close(this.selectedPositions);
  }

  public drop(event: CdkDragDrop<IPosition[]>, type: string): void {
    if (
      event.container.data.length === 3 &&
      event.previousContainer !== event.container &&
      type === 'selectedPositions'
    ) {
      alert("You can't add more than 3 items");
      return;
    }

    if (event.previousContainer === event.container && type === 'selectedPositions') {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.selectedPositions.map((sp: IPosition) => (sp.isFirst = false));
      this.selectedPositions[0].isFirst = true;
    } else {
      if (type !== 'selectedPositions') {
        const draggedPos = this.selectedPositions[event.previousIndex];
        if (draggedPos && type === draggedPos.row) {
          const [removedItem] = this.selectedPositions.splice(event.previousIndex, 1);
          this.rowArrays[type].forEach((item: IPosition) => {
            if (item && item.title === removedItem.title) {
              item.added = false;
            }
          });
        }
        this.selectedPositions[0].isFirst = true;
      }

      const idx = event.container.data.indexOf(event.previousContainer.data[event.previousIndex]);
      if (idx === -1 && type === 'selectedPositions') {
        copyArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        // Add blue border for those positions which are selected
        this.selectedPositions.forEach((position: IPosition, index: number) => {
          for (const rowName in this.rowArrays) {
            if (rowName in this.rowArrays) {
              this.rowArrays[rowName].forEach((firstP: IPosition) => {
                if (position.title === firstP.title) {
                  firstP.added = true;
                }
              });
            }
          }
          position.isFirst = index === 0;
        });
      }
    }
  }

  public trackByFunction(index: number, item: unknown): unknown {
    return item;
  }

  private checkIfAdded(value: boolean, position: IPosition): void {
    const rowArray = this.rowArrays[position?.row];

    if (rowArray) {
      rowArray.forEach((item: IPosition) => {
        if (item.title === position.title) {
          item.added = value;
        }
      });
    }
  }
}
