<header class="top-bar flex-row justify-center">
  <button class="close-icon cursor-pointer" (click)="navigateToEventsPage()" aria-label="Close">
    <mat-icon>close</mat-icon>
  </button>
  <h1 class="page-label" i18n>Position Modal</h1>
</header>
<section class="position-form">
  <div class="flex-col space-between align-center gap-25">
    <p class="option-text">
      Letters, as opposed to using 'Content here, content here', making it look like readable English. Many
    </p>

    <div class="field-background">
      <div class="field-position">
        <!-- First row -->
        <div class="first-row flex-row justify-center gap-10" id="firstRow" cdkDropList #first_row="cdkDropList"
          [cdkDropListData]="rowArrays.firstRow" cdkDropListSortingDisabled [cdkDropListConnectedTo]="[doneList]"
          (cdkDropListDropped)="drop($event, 'firstRow')" cdkDropListOrientation="horizontal">
          <div [ngClass]="{
              'remove-backgroud circle-shadow': position.added,
              'position-red position-default': true,
            }" *ngFor="let position of rowArrays.firstRow; trackBy: trackByFunction" cdkDrag [cdkDragData]="position">
            <span [ngClass]="{ 'visibility-hidden': position.added, 'position-text': true }">{{
              position.title
              }}</span>
          </div>
        </div>
        <!-- second row -->
        <div class="second-row flex-row align-center flex-start gap-10">
          <div id="secondRowOne" cdkDropList #second_row_one="cdkDropList" [cdkDropListData]="rowArrays.secondRowOne"
            cdkDropListSortingDisabled [cdkDropListConnectedTo]="[doneList]"
            (cdkDropListDropped)="drop($event, 'secondRowOne')" cdkDropListOrientation="horizontal">
            <div [ngClass]="{
                'position-red position-default': true,
                'circle-shadow remove-backgroud': position.added,
              }" *ngFor="let position of rowArrays.secondRowOne; trackBy: trackByFunction" cdkDrag
              [cdkDragData]="position">
              <span [ngClass]="{ 'visibility-hidden': position.added, 'position-text': true }">{{
                position.title
                }}</span>
            </div>
          </div>

          <div id="secondRowTwo" cdkDropList #second_row_two="cdkDropList" [cdkDropListData]="rowArrays.secondRowTwo"
            cdkDropListSortingDisabled [cdkDropListConnectedTo]="[doneList]"
            (cdkDropListDropped)="drop($event, 'secondRowTwo')" cdkDropListOrientation="horizontal">
            <div [ngClass]="{
                'position-default position-red': true,
                'circle-shadow remove-backgroud': position.added,
              }" *ngFor="let position of rowArrays.secondRowTwo; trackBy: trackByFunction" cdkDrag
              [cdkDragData]="position">
              <span [ngClass]="{ 'visibility-hidden': position.added, 'position-text': true }">{{
                position.title
                }}</span>
            </div>
          </div>

          <div id="secondRowThree" cdkDropList #second_row_three="cdkDropList"
            [cdkDropListData]="rowArrays.secondRowThree" cdkDropListSortingDisabled
            [cdkDropListConnectedTo]="[doneList]" (cdkDropListDropped)="drop($event, 'secondRowThree')"
            cdkDropListOrientation="horizontal">
            <div [ngClass]="{
                'position-default position-red': true,
                'circle-shadow remove-backgroud': position.added,
              }" *ngFor="let position of rowArrays.secondRowThree; trackBy: trackByFunction" cdkDrag
              [cdkDragData]="position">
              <span [ngClass]="{ 'visibility-hidden': position.added, 'position-text': true }">{{
                position.title
                }}</span>
            </div>
          </div>
        </div>
        <!-- third row -->
        <div class="third-row flex-row align-center flex-start gap-10">
          <div id="thirdRowOne" cdkDropList #third_row_one="cdkDropList" [cdkDropListData]="rowArrays.thirdRowOne"
            cdkDropListSortingDisabled [cdkDropListConnectedTo]="[doneList]"
            (cdkDropListDropped)="drop($event, 'thirdRowOne')" cdkDropListOrientation="horizontal">
            <div [ngClass]="{
                'position-default position-blue': true,
                'remove-backgroud circle-shadow': position.added,
              }" *ngFor="let position of rowArrays.thirdRowOne; trackBy: trackByFunction" cdkDrag>
              <span [ngClass]="{ 'visibility-hidden': position.added, 'position-text': true }">{{
                position.title
                }}</span>
            </div>
          </div>

          <div id="thirdRowTwo" cdkDropList #third_row_two="cdkDropList" [cdkDropListData]="rowArrays.thirdRowTwo"
            cdkDropListSortingDisabled [cdkDropListConnectedTo]="[doneList]"
            (cdkDropListDropped)="drop($event, 'thirdRowTwo')" cdkDropListOrientation="horizontal">
            <div [ngClass]="{
                'position-default position-blue': true,
                'remove-backgroud circle-shadow': position.added,
              }" *ngFor="let position of rowArrays.thirdRowTwo; trackBy: trackByFunction" cdkDrag>
              <span [ngClass]="{ 'visibility-hidden': position.added, 'position-text': true }">{{
                position.title
                }}</span>
            </div>
          </div>

          <div id="thirdRowThree" cdkDropList #third_row_three="cdkDropList" [cdkDropListData]="rowArrays.thirdRowThree"
            cdkDropListSortingDisabled [cdkDropListConnectedTo]="[doneList]"
            (cdkDropListDropped)="drop($event, 'thirdRowThree')" cdkDropListOrientation="horizontal">
            <div [ngClass]="{
                'position-default position-blue': true,
                'remove-backgroud circle-shadow': position.added,
              }" *ngFor="let position of rowArrays.thirdRowThree; trackBy: trackByFunction" cdkDrag>
              <span [ngClass]="{ 'visibility-hidden': position.added, 'position-text': true }">{{
                position.title
                }}</span>
            </div>
          </div>
        </div>
        <!-- Fourth row -->
        <div class="fourth-row flex-row justify-center gap-10" id="forthRow" #forth_row="cdkDropList" cdkDropList
          [cdkDropListData]="rowArrays.forthRow" cdkDropListSortingDisabled [cdkDropListConnectedTo]="[doneList]"
          (cdkDropListDropped)="drop($event, 'forthRow')" cdkDropListOrientation="horizontal">
          <div [ngClass]="{
              'position-default position-blue': true,
              'remove-backgroud circle-shadow': position.added,
            }" *ngFor="let position of rowArrays.forthRow; trackBy: trackByFunction" cdkDrag>
            <span [ngClass]="{ 'visibility-hidden': position.added, 'position-text': true }">{{
              position.title
              }}</span>
          </div>
        </div>
        <!-- fifth row -->
        <div class="fifth-row flex-row align-center flex-start gap-10">
          <div id="fifthRowOne" cdkDropList #fifth_row_one="cdkDropList" [cdkDropListData]="rowArrays.fifthRowOne"
            cdkDropListSortingDisabled [cdkDropListConnectedTo]="[doneList]"
            (cdkDropListDropped)="drop($event, 'fifthRowOne')" cdkDropListOrientation="horizontal">
            <div [ngClass]="{
                'position-default position-green': true,
                'remove-backgroud circle-shadow': position.added,
              }" *ngFor="let position of rowArrays.fifthRowOne; trackBy: trackByFunction" cdkDrag>
              <span [ngClass]="{ 'visibility-hidden': position.added, 'position-text': true }">{{
                position.title
                }}</span>
            </div>
          </div>

          <div id="fifthRowTwo" cdkDropList #fifth_row_two="cdkDropList" [cdkDropListData]="rowArrays.fifthRowTwo"
            cdkDropListSortingDisabled [cdkDropListConnectedTo]="[doneList]"
            (cdkDropListDropped)="drop($event, 'fifthRowTwo')" cdkDropListOrientation="horizontal">
            <div [ngClass]="{
                'position-default position-green': true,
                'remove-backgroud circle-shadow': position.added,
              }" *ngFor="let position of rowArrays.fifthRowTwo; trackBy: trackByFunction" cdkDrag>
              <span [ngClass]="{ 'visibility-hidden': position.added, 'position-text': true }">{{
                position.title
                }}</span>
            </div>
          </div>

          <div id="fifthRowThree" cdkDropList #fifth_row_three="cdkDropList" [cdkDropListData]="rowArrays.fifthRowThree"
            cdkDropListSortingDisabled [cdkDropListConnectedTo]="[doneList]"
            (cdkDropListDropped)="drop($event, 'fifthRowThree')" cdkDropListOrientation="horizontal">
            <div [ngClass]="{
                'position-default position-green': true,
                'remove-backgroud circle-shadow': position.added,
              }" *ngFor="let position of rowArrays.fifthRowThree; trackBy: trackByFunction" cdkDrag>
              <span [ngClass]="{ 'visibility-hidden': position.added, 'position-text': true }">{{
                position.title
                }}</span>
            </div>
          </div>
        </div>
        <!-- sixth row -->
        <div class="sixth-row flex-row justify-center gap-10" id="sixthRow" cdkDropList #sixth_row="cdkDropList"
          [cdkDropListData]="rowArrays.sixthRow" cdkDropListSortingDisabled [cdkDropListConnectedTo]="[doneList]"
          (cdkDropListDropped)="drop($event, 'sixthRow')" cdkDropListOrientation="horizontal">
          <div [ngClass]="{
              'position-default position-yellow': true,
              'remove-backgroud circle-shadow': position.added,
            }" *ngFor="let position of rowArrays.sixthRow; trackBy: trackByFunction" cdkDrag>
            <span [ngClass]="{ 'visibility-hidden': position.added, 'position-text': true }">{{
              position.title
              }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="positions">
    <h2 class="position-title">Positions</h2>
    <div class="mt-5 flex-row start align-center selected-positions" cdkDropList #doneList="cdkDropList"
      [cdkDropListConnectedTo]="[
        second_row_one,
        second_row_two,
        second_row_three,
        first_row,
        third_row_one,
        third_row_two,
        third_row_three,
        forth_row,
        fifth_row_one,
        fifth_row_two,
        fifth_row_three,
        sixth_row
      ]" (cdkDropListDropped)="drop($event, 'selectedPositions')" [cdkDropListData]="selectedPositions"
      cdkDropListOrientation="horizontal">
      <div *ngFor="let position of selectedPositions; let i = index; trackBy: trackByFunction">
        <div class="flex-row">
          <div
            [ngClass]="['mr-16', 'position-' + (position.isFirst ? 'first' : 'default'), 'position-' + position?.color]"
            cdkDrag [cdkDragData]="position">
            <span class="position-text">{{ position.title }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<footer class="submit-buttons flex-col space-between align-center gap-10">
  <button class="apply fill-button" mat-raised-button (click)="submitForm()" aria-label="Apply positions">
    <span class="va-middle" i18n>Apply</span>
  </button>
  <button class="cancel outline-button" mat-raised-button (click)="navigateToEventsPage()"
    aria-label="Cancel positions">
    <span class="va-middle" i18n>Cancel</span>
  </button>
  <button class="cancel outline-button" mat-raised-button (click)="resetForm()" aria-label="Reset positions">
    <span class="va-middle" i18n>Reset</span>
  </button>
</footer>